import MainAuthLayout from '@/views/pages/MainAuthLayout.vue';
import AuthLayout from '@/views/pages/AuthLayout.vue';
import NotFound from '@/views/NotFoundPage.vue';
const routes = [
    {
    path: '/',
    redirect: '/',
    component: MainAuthLayout,
    children: [
      {
        path: '/',
        name: 'customers',
        component: () => import(/* webpackChunkName: "demo" */ '../views/pages/customer.vue')
      },
      {
        path: '/riders',
        name: 'riders',
        component: () => import(/* webpackChunkName: "demo" */ '../views/pages/Riders.vue')
      },
      {
        path: '/partners',
        name: 'partners',
        component: () => import(/* webpackChunkName: "demo" */ '../views/pages/Partners.vue')
   },
   {
        path: '/about_us',
        name: 'about_us',
        component: () => import(/* webpackChunkName: "demo" */ '../views/pages/about_us.vue')
   },
    ]
  },
  
  {
    path: '/',
    component: AuthLayout,
    children: [
      { path: '*', component: NotFound }
    ]
  },
 
];

export default routes;
